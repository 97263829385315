.dashboard {
}

.dashboard1 {
}

.dashboard22 {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.dashboard22 img {
  width: 100%;
}

.dashboard2 {
  width: 90%;
  height: auto;
  padding: 24px;
  border-radius: 22px;
  background: #2e465808;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: auto;
  margin-top: 30px;
}

.dashboard3 {
  width: auto;
  height: auto;
  padding: 32.13px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0.36px 1.08px 0px #1a255108;
  display: flex;
  align-items: center;
  gap: 25px;
}

.dashboard4 {
  width: auto;
  height: auto;
  border-radius: 9.64px;
  padding: 19.28px 32.13px 19.28px 32.13px;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dashboard4 p {
  font-family: "Lexend", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 23.75px;
  color: #9aa3af;
  margin: 0;
}

.dashboard5 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard6 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dashboard6 p {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #e57676;
  margin: 0;
}

.dashboard6 span {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 900;
  line-height: 38.4px;
  color: #e57676;
  letter-spacing: 7%;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dashboard7 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dashboard7 p {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #10507f;
  margin: 0;
}

.dashboard7 span {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 900;
  line-height: 38.4px;
  color: #10507f;
  letter-spacing: 7%;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dashboard44 {
  width: 112px;
  height: 112px;
}
