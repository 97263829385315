.container1 {
  display: flex;
  display: flex;
  min-height: 100vh; /* This ensures the height covers the full viewport height */
  width: 100%; /* This ensures the width covers the full viewport width */
  background: #F1F1F1;
}

.sidebar55 {
  width: 260px;
  flex: 0 0 auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.child-component {
  flex: 1;
  padding: 20px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* background: #FFFFFF; */
}

.sidebar55-hidden .content {
  flex: 1 1 100%;
}
