.totalusers2 {
  display: flex;
  align-items: center;
  justify-content: end;
}

.totalusers3 {
  width: auto;
  height: auto;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  background: #febf05;
  border: 1px solid #febf05;
  cursor: pointer;
}

.totalusers3 h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 19.78px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}

.totalusers4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.totalusers5 {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.totalusers5 h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  color: #202224;
  margin: 0;
}

.totalusers6 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.totalusers7 {
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background: #fcfdfd;
  border: 0.6px solid #d5d5d5;
  display: flex;
  align-items: center;
}

.totalusers7 input {
  border: none;
  outline: none;
  flex: 1;
}

.totalusers7 input::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  color: #2b303466;
  margin: 0;
}

.totalusers8 {
}

.totalusers8 select {
  width: auto;
  height: 26px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background: #fcfdfd;
  border: 0.6px solid #d5d5d5;
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  color: #2b303466;
  margin: 0;
  outline: none;
}

.totalusers9 {
  margin-top: 40px;
}

.totalusers10 {
  width: 100%;
  overflow-x: auto;
}

.totalusers10 table {
  width: 100%;
  border-collapse: collapse;
}

.totalusers10 table thead {
  background: #f1f4f9;
}

.totalusers10 table th,
.totalusers10 table td {
  padding: 12px;
  text-align: left;
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.6;
  color: #202224;
}

.totalusers10 table tbody tr {
  height: 50px;
}

.totalusers10 table tbody td {
  font-weight: 600;
}

.totalusers10 table tbody tr:hover {
  background-color: #e0dede;
}

.totalusers11 {
  cursor: pointer;
}

.totalusers12 {
  display: flex;
  align-items: center;
}

.totalusers13 {
  width: 73.25px;
  height: 19px;
  padding: 2px 10px 2px 10px;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  color: #ffffff;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* UserDetail */

.userdetails {
}

.userdetails1 {
  margin-top: 50px;
}

.userdetails2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userdetails3 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.userdetails4 {
  width: 79px;
  height: 80px;
  border-radius: 50%;
}

.userdetails4 img {
  width: 100%;
  height: 100%;
}

.userdetails5 {
}

.userdetails5 h6 {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  color: #000000;
  margin: 0;
}

.userdetails5 p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #09b006;
  margin: 0;
  padding-top: 10px;
}

.userdetails5 span {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #2e4658;
  margin: 0;
  padding-top: 10px;
}

.userdetails6 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.userdetails6 button {
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.73px;
  color: #ffffff;
  margin: 0;
}

.userdetails7 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.userdetails8 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.userdetails8 label {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #000000;
  margin: 0;
  letter-spacing: 0.2px;
}

.userdetails8 input {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background: #f9fafb;
  border: 1px solid #dadada;
  outline: none;
  padding-left: 10px;
}

.userdetails9 {
  margin-top: 20px;
}

.userdetails10 {
  width: auto;
  height: auto;
  padding: 20px;
  background-color: #f1f4f9;
  display: flex;
  align-items: center;
  gap: 50px;
}

.userdetails11 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.userdetails11 p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  color: #000000;
  letter-spacing: 0.2px;
  margin: 0;
}

.userdetails12 {
  cursor: pointer;
}

.userdetails12 p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  color: #000000;
  letter-spacing: 0.2px;
  margin: 0;
}

.userdetails14 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}
