
.offersmodel{
    position: relative;
  }
  
  
  
  .offersmodel1{
    position: absolute;
    z-index: 1;
    right: 0;
    padding: 20px;
    cursor: pointer;
  }
  
  
  .offersmodel2{
  
  }
  
  
  .offersmodel2 img{
    width: 100%;
    height: 100%;
  }