.login {
  background-color: #2e4658;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.login2 {
  width: auto;
  height: auto;
  background: linear-gradient(
    130.59deg,
    rgba(255, 255, 255, 0.9) 3.42%,
    rgba(219, 219, 219, 0.54) 103.27%
  );
  border: 2px solid #ffffff33;
  border-radius: 18px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.login33 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login3 {
  width: 84px;
  height: 84px;
  border: 3px solid #1b85f3;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login4 {
  text-align: center;
  margin-top: 10px;
}

.login4 h5 {
  font-family: "Lexend", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #39434f;
  margin: 0;
}

.login4 p {
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: #9aa3af;
  margin: 0;
  margin-top: 10px;
}

.login5 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.login5 input {
  width: 100%;
  height: 51px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  outline: none;
  border: none;
  border-radius: 8px;
  padding-left: 10px;
}

.login5 div {
  width: 100%;
  height: 51px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  outline: none;
  border: none;
  border-radius: 8px;
}

.login5 p {
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.35px;
  color: #77878f;
  margin: 0;
  text-align: center;
}

.login6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.login7 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.login7 input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: none;
  outline: none;
}

.login7 label {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #9aa3af;
  margin: 0;
}

.login8 {
  cursor: pointer;
}

.login8 p {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #39434f;
  margin: 0;
}

.login9 {
  margin-top: 30px;
}

.login9 button {
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 16px 8px 16px 8px;
  background: #1b85f3;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: none;
  outline: none;
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #fafafa;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.login9 button:hover {  
  border-color: #1b85f3;
  outline: none;
  box-shadow: 0 0 20px rgba(27, 133, 243, 0.5);
}


.otp-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.otp-input:focus {  
  border-color: #1b85f3;
  outline: none;
  box-shadow: 0 0 5px rgba(27, 133, 243, 0.5);
}
