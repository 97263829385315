.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    
    & a {
      padding: 10px;
      border: 1px solid transparent;
      color: #505050;
      &:hover{
        color: #3965ce!important;
        border: 1px solid #cacaca;
        background-color: #e3eaf3;
      }
    }
  }
  
  
  .paginationLink {
    font-weight: bold;
    &:hover{
      background-color: red;
    }
  }
  
  .paginationActive {
    & a {
      color: #505050;
      border: 1px solid #cacaca;
      background: linear-gradient(to bottom,#fff 0,#eaeaea 100%);
    }
  }
  
  .paginationDisabled{
    & a {
      color: #cacaca;
    }
  }